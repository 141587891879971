<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="编辑企业要闻" />
		<CompanyForm :newsForm="form" @eventForm="handleGetForm" class="purForm" />
	</div>
</template>

<script>
	import CompanyForm from '@/components/Company/Form'
	export default {
		data() {
			return {
				form: {}
			}
		},
		created() {
			this.$axios.post('/getnews', {
				id: this.$route.query.id
			}).then(data => {
				this.form = data
			})
		},
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('修改成功')
					this.$router.push('/company')
				})
			}
		},
		components: {
			CompanyForm
		}
	}
</script>
<style scoped>
	.purForm{
		width: 1510px;
		margin: 10px auto;
	}
</style>
